@font-face {
  font-family: 'thin';
  src: url('./assets/font/Montserrat/Montserrat-Light.otf') format('opentype');
}

@font-face {
  font-family: 'medium';
  src: url('./assets/font/Montserrat/Montserrat-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'regular';
  src: url('./assets/font/Montserrat/Montserrat-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'bold';
  src: url('./assets/font/Montserrat/Montserrat-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'headline';
  src: url('./assets/font/Montserrat/Montserrat-Bold.otf') format('opentype');
}
